<template>
  <div class="row mb-5">
    <el-table
      class="table-responsive align-items-center table-flush"
      header-row-class-name="thead-light"
      :data="purchasesInvoice.items"
    >
      <el-table-column :label="$t('COMMON.CODE')" prop="code">
        <template v-slot="{ row }">
          <div v-if="row.purchasesInvoiceable">
            <a
              href="#"
              @click.prevent="
                goToPurchasesInvoiceable(row.purchasesInvoiceable)
              "
            >
              {{ row.code }}<br />
              <div
                class="text-muted purchases-invoice-item-excerpt"
                v-html="row.excerpt"
              ></div>
            </a>
          </div>
          <div v-if="!row.purchasesInvoiceable">
            {{ row.code }}<br />
            <div
              class="text-muted purchases-invoice-item-excerpt"
              v-html="row.excerpt"
            ></div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMMON.UNIT_PRICE')"
        prop="unit_price"
        class-name="number-column"
        label-class-name="number-column"
      >
        <template v-slot="{ row }">
          {{ $formatCurrency(row.unit_price) }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMMON.QUANTITY')"
        class-name="number-column"
        label-class-name="number-column"
      >
        <template v-slot="{ row }">
          {{ row.quantity }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMMON.FORMAT')"
        class-name="number-column"
        label-class-name="number-column"
      >
        <template v-slot="{ row }">
          <object-link :object="row.unitOfMeasureUnit" />
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMMON.SUBTOTAL')"
        class-name="number-column"
        label-class-name="number-column"
      >
        <template v-slot="{ row }">
          {{ $formatCurrency(row.pricing.subtotal) }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMMON.TAXES')"
        class-name="number-column"
        label-class-name="number-column"
      >
        <template v-slot="{ row }">
          {{ $formatCurrency(row.pricing.taxes.total) }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMMON.TOTAL')"
        class-name="number-column"
        label-class-name="number-column"
      >
        <template v-slot="{ row }">
          {{ $formatCurrency(row.pricing.total) }}
        </template>
      </el-table-column>

      <el-table-column
        min-width="50px"
        align="center"
        v-if="purchasesInvoice.status === INVOICE_STATUS_DRAFT"
      >
        <div slot-scope="{ row }" class="table-actions">
          <el-tooltip :content="$t('COMMON.EDIT')" placement="top">
            <a
              type="text"
              @click="openPurchasesInvoiceItemModal(row)"
              class="table-action"
              data-toggle="tooltip"
              style="cursor: pointer"
            >
              <i class="fas fa-edit"></i>
            </a>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { INVOICE_STATUS_DRAFT } from "@/constants/invoices";

export default {
  name: "purchases-invoice-view-details-purchases-invoice-item-table",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["purchasesInvoice"],

  data() {
    return {
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
    };
  },

  computed: {},

  methods: {
    openPurchasesInvoiceItemModal(purchasesInvoiceItem) {
      this.$emit("onOpenPurchasesInvoiceItemModal", purchasesInvoiceItem);
    },

    async goToPurchasesInvoiceable(purchasesInvoiceable) {
      this.$router.push(this.$objectViewRoute(purchasesInvoiceable));
    },
  },

  mounted() {},

  watch: {},
};
</script>

<style>
.purchases-invoice-item-excerpt p,
.purchases-invoice-item-excerpt,
.purchases-invoice-item-excerpt * {
  font-size: 0.7rem;
}
</style>
