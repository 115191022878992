export default {
  type: "purchases-invoice-items",
  code: null,
  excerpt: "",
  unit_price: null,
  quantity: 1,
  discount: 0,
  relationshipNames: [
    "purchasesInvoice",
    "purchasesInvoiceable",
    "taxGroups",
    "unitOfMeasureUnit",
  ],
  purchasesInvoice: {
    type: "purchases-invoices",
    id: null,
  },
  purchasesInvoiceable: {
    type: "products",
    id: null,
  },
  pricing: {},
  taxGroups: [],
  unitOfMeasureUnit: {
    type: "unit-of-measure-units",
    id: null,
  },
};
