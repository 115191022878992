<template>
  <div class="container-fluid">
    <div class="row mb-5" v-if="showHeader">
      <div class="col-8">
        <span class="display-4 col-12">{{ $t("COMMON.DETAILS") }}</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openPurchasesInvoiceItemModal()"
          type="button"
          class="btn btn-sm btn-primary"
          v-if="purchasesInvoice.status === INVOICE_STATUS_DRAFT"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          {{ $t("PURCHASES_INVOICES.ADD_PURCHASES_INVOICE_ITEM") }}
        </base-button>
      </div>
    </div>

    <purchases-invoice-view-details-purchases-invoice-item-table
      :purchasesInvoice="purchasesInvoice"
      @onOpenPurchasesInvoiceItemModal="openPurchasesInvoiceItemModal"
    />

    <div class="row mb-5" v-if="purchasesInvoice.pricing">
      <div class="col-6"></div>
      <div class="col-6">
        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.SUBTOTAL") }}</dt>
          <dd class="col-sm-6 text-right px-0">
            {{ $formatCurrency(purchasesInvoice.pricing.subtotal) }}
          </dd>
        </dl>

        <dl class="row mb-0" v-if="purchasesInvoice.pricing.discounts.amount">
          <dt class="col-sm-6">{{ $t("COMMON.DISCOUNTS") }}</dt>
          <dd class="col-sm-6 text-right px-0">
            {{
              $formatCurrency(purchasesInvoice.pricing.discounts.amount * -1)
            }}
          </dd>
          <!-- <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(discount, index) in purchasesInvoice.pricing.discounts
                .details"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ $te(`COMMON.${discount.name}`) ? $t(`COMMON.${discount.name}`) : discount.name }}</dt>
              <dd class="col-sm-6 text-right text-muted px-0">
                {{ $formatCurrency(discount.amount * -1) }}
              </dd>
            </dl>
          </div> -->
        </dl>

        <dl class="row mb-0" v-if="purchasesInvoice.pricing.taxes.total">
          <dt class="col-sm-6">{{ $t("COMMON.TAXES") }}</dt>
          <dd class="col-sm-6 text-right px-0">
            {{ $formatCurrency(purchasesInvoice.pricing.taxes.total) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0 px-0 mx-0"
              v-for="(tax, index) in purchasesInvoice.pricing.taxes.taxes"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ tax.label }}</dt>
              <dd class="col-sm-6 text-right text-muted px-0">
                {{ $formatCurrency(tax.tax_amount) }}
              </dd>
            </dl>
          </div>
        </dl>

        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-6 text-right px-0">
            {{ $formatCurrency(purchasesInvoice.pricing.total) }}
          </dd>
        </dl>
      </div>
    </div>

    <!-- Modal add slot -->
    <purchases-invoice-view-details-purchases-invoice-item-form
      :purchasesInvoice="purchasesInvoice"
      :purchasesInvoiceItem="purchasesInvoiceItem"
      :showModal="showModal"
      :formErrors="itemsFormError"
      @onClosePurchasesInvoiceItemModal="closePurchasesInvoiceItemModal"
      @onSubmitPurchasesInvoiceItemForm="handlePurchasesInvoiceItemFormSubmit"
      @onDeletePurchasesInvoiceItem="deletePurchasesInvoiceItem"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import { cloneDeep } from "lodash";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
} from "@/constants/invoices";
import PurchasesInvoiceViewDetailsPurchasesInvoiceItemForm from "./PurchasesInvoiceViewDetailsPurchasesInvoiceItemForm.vue";
import PurchasesInvoiceViewDetailsPurchasesInvoiceItemTable from "./PurchasesInvoiceViewDetailsPurchasesInvoiceItemTable.vue";
import defaultPurchasesInvoiceItem from "../defaultPurchasesInvoiceItem";

export default {
  name: "purchases-invoice-view-details",

  components: {
    PurchasesInvoiceViewDetailsPurchasesInvoiceItemForm,
    PurchasesInvoiceViewDetailsPurchasesInvoiceItemTable,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    showHeader: {
      default: true,
    },
    purchasesInvoice: {
      default: {},
    },
  },

  data() {
    return {
      purchasesInvoiceItem: cloneDeep(defaultPurchasesInvoiceItem),
      loading: false,
      showModal: false,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      itemsFormError: null,
    };
  },

  computed: {},

  methods: {
    openPurchasesInvoiceItemModal(purchasesInvoiceItem = null) {
      if (!purchasesInvoiceItem) {
        this.purchasesInvoiceItem = cloneDeep(defaultPurchasesInvoiceItem);
      } else {
        this.purchasesInvoiceItem = cloneDeep(purchasesInvoiceItem);
      }
      this.showModal = true;
    },

    closePurchasesInvoiceItemModal() {
      this.showModal = false;
      this.loading = false;
    },

    handlePurchasesInvoiceItemFormSubmit(purchasesInvoiceItemData) {
      if (!purchasesInvoiceItemData.id) {
        this.addPurchasesInvoiceItem(purchasesInvoiceItemData);
      } else {
        this.editPurchasesInvoiceItem(purchasesInvoiceItemData);
      }
    },

    async addPurchasesInvoiceItem(purchasesInvoiceItemData) {
      this.loading = true;
      try {
        purchasesInvoiceItemData.purchasesInvoice.id = this.purchasesInvoice.id;

        await this.$store.dispatch(
          "purchasesInvoiceItems/add",
          purchasesInvoiceItemData
        );
        this.$emit("purchasesInvoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_INVOICES.PURCHASES_INVOICE_ITEM_ADDED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
      }
    },

    async editPurchasesInvoiceItem(purchasesInvoiceItemData) {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "purchasesInvoiceItems/update",
          purchasesInvoiceItemData
        );
        this.$emit("purchasesInvoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_INVOICES.PURCHASES_INVOICE_ITEM_EDITED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
      }
    },

    async deletePurchasesInvoiceItem(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("purchasesInvoiceItems/destroy", id);
        this.$emit("purchasesInvoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_INVOICES.PURCHASES_INVOICE_ITEM_DELETED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
